import { z } from 'zod';
import { zodString } from '@server/utils/zodUtils';

export const LoginInSchema = z.object({
    memberId: zodString('아이디'),
    password: zodString('패스워드'),
});

export type LoginInDTO = z.infer<typeof LoginInSchema>;

export const LoginStateSchema = z.object({
    memberId: z.string(),
    memberName: z.string(),
    accessToken: z.string(),
    refreshToken: z.string(),
    expirationDate: z.number().nullable(),
    headOfficeMember: z.string(),
    memberRole: z.string(),
});

export type LoginStateDTO = z.infer<typeof LoginStateSchema>;

export const TokenSchema = z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
});

export type TokenDTO = z.infer<typeof TokenSchema>;
