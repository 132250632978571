import { zodResolver } from '@hookform/resolvers/zod';
import { ActionFunctionArgs, json, LoaderFunction, type MetaFunction, redirect } from '@remix-run/node';
import { Form } from '@remix-run/react';
import { useForm } from 'react-hook-form';
import logo from '@assets/images/logo.png';
import B from '@components/button';
import Tag from '@components/input';
import { useCustomSubmit } from '@hooks/useCustomSubmit';
import { LoginInDTO, LoginInSchema, LoginStateDTO } from '@server/login/login.models';
import { createUserSession, isAuthenticate, postLogin } from '@server/login/login.server';
import { ApiResponseMessage } from '@server/models/common.models';
import { customToast } from '@server/toast.server';

const resolver = zodResolver(LoginInSchema);

export const loader: LoaderFunction = async ({ request }) => {
    const isLogin = await isAuthenticate(request);
    if (isLogin) {
        return redirect('/');
    }

    return json({});
};

export const action = async ({ request }: ActionFunctionArgs) => {
    const formData = await request.json();

    const result = await postLogin(formData);

    if (result.status === 200) {
        return await createUserSession({
            request,
            redirectTo: '/',
            logininfo: {
                ...result.content!,
            },
        });
    }

    return json<ApiResponseMessage<LoginStateDTO>>(result, {
        headers: await customToast<LoginStateDTO>('error', result),
    });
};

export const meta: MetaFunction = () => {
    return [{ title: 'BODIUM > 로그인' }];
};

export default function Login() {
    const { submit } = useCustomSubmit();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginInDTO>({
        resolver: resolver,
        defaultValues: { memberId: '', password: '' },
    });

    const onSubmit = (data: LoginInDTO) => {
        submit(data);
    };

    return (
        <>
            <div className="login flex w100 h100 ai jc">
                <div className="login_con">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex ai jc w100 mb30">
                            <img className="login_logo mx140" src={logo} alt="" />
                        </div>

                        <div className="form_list">
                            <div className="unit mb6">
                                <Tag.Input.R
                                    className="w100"
                                    type="text"
                                    register={register('memberId')}
                                    placeholder="아이디"
                                    errorMessage={errors.memberId ? errors.memberId.message : ''}
                                />
                            </div>
                            <div className="unit mb6">
                                <Tag.Input.R
                                    className="w100"
                                    type="password"
                                    register={register('password')}
                                    placeholder="비밀번호"
                                    errorMessage={errors.password ? errors.password.message : ''}
                                />
                            </div>
                            <B.Button className="bodium w100 flex jc ">로그인</B.Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}
